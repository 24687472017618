<template>
  <base-card title="Reservation Details" :link-url="`/reservations/${reservation.id}/edit`" :link-text="`${authUser.role === 'admin' || authUser.role === 'manager' ? 'Edit' : ''
    }`">
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col>
          <!-- Status -->
          <base-field-display label-text="Status" lines="none">
            <p class="ion-text-capitalize">{{ reservation.status }}</p>
          </base-field-display>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <!-- Property -->
          <base-field-display label-text="Property" lines="none">
            <p>{{ reservation.property.name }}</p>
          </base-field-display>
        </ion-col>
      </ion-row>

      <ion-row>
        <!-- Arrival Date -->
        <ion-col size="6">
          <base-field-display label-text="Arrival Date" lines="none">
            <p>
              {{ formatDate(reservation.arrival_date, "eeee, MMM d, yyyy") }}
            </p>
          </base-field-display>
        </ion-col>

        <ion-col size="6">
          <!-- Departure Date -->
          <base-field-display label-text="Departure Date" lines="none">
            <p>
              {{ formatDate(reservation.departure_date, "eeee, MMM d, yyyy") }}
            </p>
          </base-field-display>
        </ion-col>

        <ion-col size="12">
          <!-- Number Nights -->
          <base-field-display label-text="Number Nights" lines="none">
            <p>
              {{
    Math.floor(differenceInHours(
      new Date(reservation.departure_date),
      new Date(reservation.arrival_date)) / 24)
  }} nights,

              {{
      Math.floor(differenceInHours(
        new Date(reservation.departure_date),
        new Date(reservation.arrival_date)) / 24) + 1
    }} days
            </p>
          </base-field-display>
        </ion-col>
      </ion-row>

      <ion-row>
        <!-- Room Type -->
        <ion-col size="6">
          <base-field-display label-text="Room Type" lines="none">
            <p v-if="!reservation.room_type">Unassigned</p>
            <p v-if="reservation.room_type">
              {{ reservation.room_type.name }}
            </p>
          </base-field-display>
        </ion-col>

        <ion-col size="6">
          <!-- Room -->
          <base-field-display label-text="Room" lines="none">
            <p v-if="!reservation.room">Unassigned</p>
            <p v-if="reservation.room">
              {{ reservation.room.number }}
            </p>
          </base-field-display>
        </ion-col>
      </ion-row>

      <ion-row v-if="authUser.role === 'admin' || authUser.role === 'manager'">
        <ion-col size="6">
          <!-- Nightly Rate -->
          <base-field-display label-text="Nightly Rate" lines="none">
            <p v-if="price_nightly">
              ${{
    price_nightly.toLocaleString("en", {
      minimumFractionDigits: 2,
    })
  }}
            </p>
          </base-field-display>
        </ion-col>

        <ion-col size="6">
          <!-- Deposit -->
          <base-field-display label-text="Deposit" lines="none">
            <p v-if="reservation.price_final">
              ${{
    reservation.price_deposit.toLocaleString("en", {
      minimumFractionDigits: 2,
    })
  }}

              {{ depositPaid ? "(Paid)" : "(Unpaid)" }}
            </p>
          </base-field-display>
        </ion-col>

        <ion-col size="6">
          <!-- Retail Price -->
          <base-field-display label-text="Retail Price (USD)" lines="none">
            <p v-if="reservation.price_retail">
              ${{
    reservation.price_retail.toLocaleString("en", {
      minimumFractionDigits: 2,
    })
  }}
            </p>
          </base-field-display>
        </ion-col>
        <ion-col size="6">
          <!-- Final Price -->
          <base-field-display label-text="Guest Price (before taxes & fees)" lines="none">
            <p v-if="reservation.price_final">
              ${{
    reservation.price_final.toLocaleString("en", {
      minimumFractionDigits: 2,
    })
  }}
            </p>
          </base-field-display>
        </ion-col>

        <ion-col size="6">
          <!-- Estimated Taxes -->
          <base-field-display label-text="Estimated Taxes" lines="none">
            <p v-if="reservation.price_final">
              ${{
    reservation.price_estimated_taxes_fees.toLocaleString("en", {
      minimumFractionDigits: 2,
    })
  }}
            </p>
          </base-field-display>
        </ion-col>

        <ion-col size="6">
          <!-- Estimated Total -->
          <base-field-display label-text="Estimated Total" lines="none">
            <p v-if="reservation.price_final">
              ${{
    reservation.price_estimated_total.toLocaleString("en", {
      minimumFractionDigits: 2,
    })
  }}
            </p>
          </base-field-display>
        </ion-col>

        <ion-col size="6">
          <!-- Comped -->
          <base-field-display label-text="Complimentary Stay" lines="none">
            <p>{{ (reservation.comped) ? 'Yes' : 'No' }}</p>
          </base-field-display>
        </ion-col>


        <ion-col size="6">
          <!-- Comped -->
          <base-field-display label-text="Support Person Meals" lines="none">
            <p>{{ (reservation.support_person_meals) ? 'Yes' : 'No' }}</p>
          </base-field-display>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <!-- Checkout Link -->
          <base-field-display label-text="Reservation Checkout Link" lines="none" class="margin-bottom-16">
            <p>
              Provide the below link to guests via email or SMS for them to
              confirm their reservation.
            </p>
            <p>
              <a :href="reservationCheckoutLink" target="_blank">{{
    reservationCheckoutLink
  }}</a>
            </p>
          </base-field-display>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <!-- Reservation Hold Expiration -->
          <base-field-display label-text="Reservation Hold Expiration" lines="none" class="">
            <p>
              This reservation hold expires on
              {{ formatDate(reservation.expiration_date, "M/d 'at' h:ma") }}. Guest must
              finalize the reservation with the above link before this date.
            </p>
          </base-field-display>

          <ion-button expand="block" class="margin-bottom-16" @click="extendReservationHold"
            v-if="authUser.role === 'admin' || authUser.role === 'manager'">
            Extend Reservation Hold
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <!-- Notes -->
          <base-field-display label-text="General Notes" lines="none">
            <p>{{ reservation.general_notes }}</p>
          </base-field-display>
        </ion-col>

        <ion-col size="12" v-if="reservation.cancellation_notes">
          <!-- Notes -->
          <base-field-display label-text="Cancellation Notes" lines="none">
            <p>{{ reservation.cancellation_notes }}</p>
          </base-field-display>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-card>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonButton } from "@ionic/vue";
import { formatDate, format24to12hr, formatTimeAndDate } from "@/util/helpers";
import { differenceInHours } from "date-fns";
import { mapGetters } from "vuex";

export default {
  props: {
    reservation: {
      type: Object,
      required: true,
    },
    emailReservationMethod: {
      type: Function,
    },
  },

  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
  },

  data() {
    return {
      formatDate,
      format24to12hr,
      formatTimeAndDate,
      differenceInHours
    };
  },

  computed: {
    ...mapGetters(["authUser"]),

    depositPaid() {
      let depositTransaction = this.reservation.folio.transactions.find(
        (x) => x.is_deposit
      );

      if (
        depositTransaction &&
        depositTransaction.stripe_payment_intent_status === "succeeded"
      )
        return true;

      return false;
    },

    reservationCheckoutLink() {
      return `${process.env.VUE_APP_GUEST_PORTAL}/r/${this.reservation.code}`;
    },

    price_nightly() {
      if (this.reservation.departure_date && this.reservation.arrival_date) {
        return (
          this.reservation.price_final /
          Math.floor(differenceInHours(
            new Date(this.reservation.departure_date),
            new Date(this.reservation.arrival_date)) / 24)
        );
      }

      return 0;
    },
  },

  methods: {
    extendReservationHold() {
      this.$emit("extend-reservation-hold");
      console.log('extend reservation hold emit')
    }
  }
};
</script>