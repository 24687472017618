<template>
  <div class="ion-text-center">
    <div class="ion-no-padding ion-text-uppercase day-of-week">
      {{ formatDate(datetime, "eee") }}
    </div>
    <div class="ion-no-padding date-number">
      {{ formatDate(datetime, "d") }}
    </div>
    <div class="ion-no-padding ion-text-uppercase month">
      {{ formatDate(datetime, "MMM") }}
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/util/helpers";

export default {
  props: {
    datetime: {
      type: String,
      required: true,
    },
  },

  components: {},

  data() {
    return {
      formatDate
    };
  },
};
</script>

<style scoped>
.day-of-week {
  font-size: 12px;
  font-weight: 700;
}

.date-number {
  font-size: 24px;
  font-weight: 500;
}

.month {
  font-size: 16px;
  font-weight: 600;
}
</style>