<template>
  <base-card title="Action Items">
    <ion-accordion-group>
      <ion-accordion value="colors">
        <ion-item slot="header" class="ion-no-padding">
          <ion-label>{{ completionPercentage }} Complete</ion-label>
        </ion-item>

        <ion-list slot="content">
          <ion-item
            class="ion-no-padding"
            v-for="(item, index) in checklistItems"
            :key="index"
            :router-link="item.link"
          >
            <ion-icon
              :icon="item.icon"
              :color="item.color"
              slot="start"
            ></ion-icon>
            <ion-label>{{ item.title }}</ion-label>
          </ion-item>
        </ion-list>
      </ion-accordion>
    </ion-accordion-group>
  </base-card>
</template>

<script>
import {
  IonAccordionGroup,
  IonAccordion,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
} from "@ionic/vue";
import { checkboxOutline, squareOutline } from "ionicons/icons";

export default {
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonAccordionGroup,
    IonAccordion,
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
  },

  data() {
    return {
      checkboxOutline,
      squareOutline,
    };
  },

  computed: {
    completionPercentage() {
      let progress = 0;
      if (this.hasDocuments) progress++;
      if (this.hasDeposit) progress++;

      return `${((progress / 2) * 100).toLocaleString("en", {
        maximumFractionDigits: 0,
      })}%`;
    },

    hasDocuments() {
      return false;
    },

    hasDeposit() {
      return false;
    },

    hasSentConfirmation(){
      return false;
    },

    checklistItems() {
      // List of Items that the user needs to do
      let checklist = [];

      checklist = [
        {
          title: "1. Email reservation to guest",
          link: ``,
          icon: this.hasSentConfirmation ? this.checkboxOutline : this.squareOutline,
          color: this.hasSentConfirmation ? "primary" : "danger",
        },
        {
          title: "2. Guest has signed all agreements",
          link: `/reservations/${this.reservation.id}/documents`,
          icon: this.hasDocuments ? this.checkboxOutline : this.squareOutline,
          color: this.hasDocuments ? "primary" : "danger",
        },
        {
          title: "3. Guest has paid deposit",
          link: `/users/${this.reservation.id}/folios/1`,
          icon: this.hasDeposit ? this.checkboxOutline : this.squareOutline,
          color: this.hasDeposit ? "primary" : "danger",
        },
        {
          title: "4. Check-in Guest",
          link: `/users/${this.reservation.id}/folios/1`,
          icon: this.hasDeposit ? this.checkboxOutline : this.squareOutline,
          color: this.hasDeposit ? "primary" : "danger",
        },
        {
          title: "6. Finalize Folio and Check-out Guest",
          link: `/users/${this.reservation.id}/folios/1`,
          icon: this.hasDeposit ? this.checkboxOutline : this.squareOutline,
          color: this.hasDeposit ? "primary" : "danger",
        },
      ];

      return checklist;
    },
  },
};
</script>