<template>
  <base-layout-two :page-title="`Reservation ${reservation.confirmation}`" page-default-back-link="/reservations">
    <section class="ion-padding background-white">
      <h1>Reservation {{ reservation.confirmation }}</h1>

      <!-- Reservation Status -->
      <ion-chip :color="reservationStatusColor">
        <ion-label class="ion-text-capitalize">
          {{ reservation.status }}
        </ion-label>
      </ion-chip>

      <!-- Arrival Departure Status -->
      <ion-chip :color="arrivalDepartureStatus.color" v-if="arrivalDepartureStatus.title">
        <ion-label class="ion-text-capitalize">
          {{ arrivalDepartureStatus.title }}
        </ion-label>
      </ion-chip>

      <!-- Checkin Status -->
      <ion-chip :color="checkinStatus.color">
        <ion-label class="ion-text-capitalize">
          {{ checkinStatus.title }}
        </ion-label>
      </ion-chip>

      <reservation-date-display :startDate="reservation.arrival_date" :endDate="reservation.departure_date"
        class="margin-top-16"></reservation-date-display>

      <!-- Check In Button -->
      <ion-button expand="block" class="" v-if="!reservation.checkin_date"
        :disabled="reservation.status === 'confirmed' ? false : true" @click="showCheckinAlert = true">
        Check In
      </ion-button>

      <!-- Check Out Button -->
      <ion-button expand="block" color="danger" v-if="reservation.checkin_date && !reservation.checkout_date"
        @click="showCheckoutAlert = true">
        Check Out
      </ion-button>
    </section>

    <!-- Main Section -->
    <section class="padding-8">
      <ion-grid class="ion-no-padding grid-padding">
        <ion-row v-if="false">
          <ion-col>
            <checklist-section :reservation="reservation"></checklist-section>
          </ion-col>
        </ion-row>

        <ion-row>
          <!-- Guest Card -->
          <ion-col>
            <base-card title="Guest">
              <user-preview-card :user="reservation.user"
                :router-link="`/users/${reservation.user.id}`"></user-preview-card>
            </base-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <!-- Reservation Details Card -->
          <ion-col size="12" size-lg="6">
            <reservation-details-section :reservation="reservation"
              @extend-reservation-hold="showExtendReservationHoldAlert = true"></reservation-details-section>
          </ion-col>

          <!-- Monitors Section -->
          <ion-col size="12" size-lg="6">
            <base-card title="Baby Monitors">
              <ion-item class="ion-no-padding" v-for="monitor in reservation.monitors" :key="monitor.id" lines="none">
                <ion-label>
                  <a :href="`https://monitors.boramcare.com?code=${monitor.code}`" target="_blank"
                    style="text-decoration: none">{{ monitor.name }}
                  </a>
                </ion-label>

                <ion-label slot="end">
                  <ion-button fill="clear" class="ion-no-padding ion-no-margin" color="danger"
                    @click="removeMonitor(monitor.id)">Remove monitor
                  </ion-button>
                </ion-label>
              </ion-item>

              <ion-button expand="block" fill="outline" @click="() => {
      showAssignMonitor = !showAssignMonitor;
    }
    ">
                Assign new baby monitor
              </ion-button>

              <section v-if="showAssignMonitor" class="margin-top-16">
                <v-form @submit="assignMonitor">
                  <base-input label-text="Select a monitor">
                    <v-field name="addMonitorId" v-slot="{ field }" v-model="addMonitorId">
                      <select v-bind="field" class="regular-select">
                        <option v-for="monitor in monitors" :key="monitor.id" :value="monitor.id">
                          {{ monitor.name }}
                          {{
    monitor.reservation
      ? `(Assigned to ${monitor.reservation.confirmation})`
      : ``
  }}
                        </option>
                      </select>
                    </v-field>
                  </base-input>

                  <ion-button type="submit" expand="block" :disabled="!addMonitorId">Assign
                  </ion-button>
                </v-form>
              </section>
            </base-card>
          </ion-col>

          <!-- Agreements Section -->
          <ion-col size="12" size-lg="6" v-if="authUser.role === 'admin' || authUser.role === 'manager'">
            <base-card title="Agreements">
              <ion-button expand="block" href="https://docusign.com" target="_blank">
                Access Reservation Agreements in Docusign
              </ion-button>
            </base-card>
          </ion-col>

          <ion-col size="12" size-lg="6" v-if="authUser.role === 'admin' || authUser.role === 'manager'">
            <!-- Folio Card -->
            <base-card title="Folio" link-text="See More"
              :link-url="`/users/${reservation.user.id}/folios/${reservation.folio.id}`">
              <folio-preview-card :folio="reservation.folio">
              </folio-preview-card>
            </base-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>

    <!-- Email Reservation Alert -->
    <ion-alert :is-open="showEmailReservationDetailsAlert" header="Email Reservation Details?"
      :message="`This will send an email to ${reservation.user.email} with reservation details.`"
      :buttons="emailReservationDetailsAlertButtons"
      @didDismiss="() => (this.showEmailReservationDetailsAlert = false)">
    </ion-alert>

    <!-- Check in Alert -->
    <ion-alert :is-open="showCheckinAlert" header="Check In Guest" :message="`Are you sure?`"
      :buttons="checkinAlertButtons" @didDismiss="() => (this.showEmailReservationDetailsAlert = false)">
    </ion-alert>

    <!-- Check out Alert -->
    <ion-alert :is-open="showCheckoutAlert" header="Check out Guest" :message="`Are you sure?`"
      :buttons="checkoutAlertButtons" @didDismiss="() => (this.showEmailReservationDetailsAlert = false)">
    </ion-alert>

    <!-- Extend Reservation Hold Alert -->
    <ion-alert :is-open="showExtendReservationHoldAlert" header="Extend Reservation Hold?"
      :message="`This will extend the reservation hold to 5 days from today.`"
      :buttons="extendReservationHoldAlertButtons" @didDismiss="() => (this.showExtendReservationHoldAlert = false)">
    </ion-alert>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonChip,
  IonAlert,
  IonItem,
  IonLabel,
} from "@ionic/vue";
import UserPreviewCard from "@/components/users/UserPreviewCard.vue";
import FolioPreviewCard from "@/components/users/folios/FolioPreviewCard.vue";
import ReservationDateDisplay from "@/components/reservations/ReservationDateDisplay.vue";
import ReservationDetailsSection from "@/components/reservations/ReservationDetailsSection.vue";
import ChecklistSection from "@/components/reservations/checklists/ChecklistSection.vue";
import { Field, Form } from "vee-validate";
import { mapActions } from "vuex";
import { format, addDays } from "date-fns";
import { mapGetters } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonChip,
    IonAlert,
    IonItem,
    IonLabel,
    UserPreviewCard,
    FolioPreviewCard,
    ReservationDetailsSection,
    ReservationDateDisplay,
    ChecklistSection,
    VForm: Form,
    VField: Field,
  },

  data() {
    return {
      monitors: [],
      reservation: {
        user: {},
        property: {},
        room: {},
        room_type: {},
        folio: {
          transactions: [],
        },
        arrival_date: "",
        departure_date: "",
      },
      showAssignMonitor: false,
      addMonitorId: null,
      reservationAgreementRecipientView: "",
      showEmailReservationDetailsAlert: false,
      emailReservationDetailsAlertButtons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => { },
        },
        {
          text: "Email Now",
          handler: () => {
            this.emailReservationDetails();
          },
        },
      ],
      showCheckinAlert: false,
      checkinAlertButtons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            this.showCheckinAlert = false;
          },
        },
        {
          text: "Yes, Check In",
          handler: () => {
            this.checkin();
          },
        },
      ],
      showCheckoutAlert: false,
      checkoutAlertButtons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            this.showCheckoutAlert = false;
          },
        },
        {
          text: "Yes, Check Out",
          handler: () => {
            this.checkout();
          },
        },
      ],
      showExtendReservationHoldAlert: false,
      extendReservationHoldAlertButtons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            this.showExtendReservationHoldAlert = false;
          },
        },
        {
          text: "Yes, extend",
          handler: () => {
            this.extendReservationHold();
          },
        },
      ],
    };
  },

  async ionViewWillEnter() {
    this.setShowLoading(false);
    await this.fetchReservation();
    await this.fetchMonitors();
  },

  computed: {
    ...mapGetters(["authUser"]),

    reservationStatusColor() {
      if (this.reservation.status === "hold") return "tertiary";
      else if (this.reservation.status === "confirmed") return "primary";
      else if (this.reservation.status === "cancelled") return "danger";

      return "tertiary";
    },

    checkinStatus() {
      if (this.reservation.checkout_date)
        return { color: "danger", title: "Checked-Out" };
      else if (this.reservation.checkin_date)
        return { color: "primary", title: "Checked-In" };

      return { color: "", title: "Not Checked-in" };
    },

    arrivalDepartureStatus() {
      let today = format(new Date(), "yyyy-MM-dd");
      let status = {
        color: "primary",
        title: null,
      };

      if (this.reservation.arrival_date) {
        // Arriving
        if (this.reservation.arrival_date === today)
          status = { color: "warning", title: "Arriving" };
        // Departing
        else if (this.reservation.departure_date === today)
          status = { color: "danger", title: "Departing" };
        // In House
        else if (
          this.reservation.checkin_date &&
          !this.reservation.checkout_date
        )
          status = { color: "primary", title: "In-House" };
      }

      return status;
    },
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchReservation() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/reservations/${this.$route.params.id}`)
        .then(async (response) => {
          this.reservation = response.data.reservation;

          // await this.fetchReservationAgreementRecipientView();
        })
        .catch((error) => {
          console.log(error);
        });
    },


    // Check In
    async checkin() {
      this.showCheckinAlert = false;

      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/reservations/${this.reservation.id}`,
        data: this.reservation,
      };

      this.reservation.checkin_date = format(new Date(), "yyyy-MM-dd");
      this.reservation.checkin_time = format(new Date(), "hh:mm:ss");

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);

          if (response.message) {
            this.loadToast({ message: response.message, color: "secondary" });
          }
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },


    // Check Out
    async checkout() {
      this.showCheckoutAlert = false;

      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/reservations/${this.reservation.id}`,
        data: this.reservation,
      };

      this.reservation.checkout_date = format(new Date(), "yyyy-MM-dd");
      this.reservation.checkout_time = format(new Date(), "hh:mm:ss");

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);

          if (response.data.reservation) {
            this.reservation = response.data.reservation
          }
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },


    // Extend Reservation Hold
    async extendReservationHold() {
      this.showExtendReservationHoldAlert = false;

      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/reservations/${this.reservation.id}`,
        data: {
          expiration_date: format(
            addDays(new Date(), 5),
            "yyyy-MM-dd hh:mm:ss"
          ),
          status: "hold",
        },
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);

          if (response.data.reservation) {
            this.loadToast({
              message: "Successfully updated reservation",
              color: "secondary",
            });

            this.fetchReservation();
          }
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },


    async emailReservationDetails() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/reservations/${this.reservation.id}/emailreservationdetails`,
        data: {},
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);

          if (response.message) {
            this.loadToast({ message: response.message, color: "secondary" });
          }
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },


    async fetchReservationAgreementRecipientView() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/agreements/recipientview`,
        data: {
          user_id: this.reservation.user_id,
          envelope_id: this.reservation.docusign_envelope_id,
        },
      };

      await this.axios(config)
        .then((response) => {
          this.reservationAgreementRecipientView = response.data.viewUrl;
        })
        .catch((error) => {
          console.log(error);
        });
    },


    /**
     * Fetch Monitors for the selected property
     */
    async fetchMonitors() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/monitors?property_id=${this.reservation.property_id}`
        )
        .then((response) => {
          this.monitors = response.data.monitors;
        })
        .catch((error) => {
          console.log(error);
        });
    },


    // Remove a Baby Monitor
    async removeMonitor(monitorId) {
      let monitor = this.monitors.find((x) => x.id === monitorId);

      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/monitors/${monitorId}`,
        data: monitor,
      };

      config.data.reservation_id = null;

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then(() => {
          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({
            message: "Monitor removed",
            color: "secondary",
          });

          this.showAssignMonitor = false;

          this.fetchReservation();
          this.fetchMonitors();
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },


    // Add the Baby Monitor
    async assignMonitor() {
      let monitor = this.monitors.find((x) => x.id === this.addMonitorId);

      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/monitors/${this.addMonitorId}`,
        data: monitor,
      };

      config.data.reservation_id = this.reservation.id;

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then(() => {
          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({
            message: "Monitor added",
            color: "secondary",
          });

          this.showAssignMonitor = false;
          this.addMonitor = null;

          this.fetchReservation();
          this.fetchMonitors();
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>