<template>
  <ion-grid>
    <ion-row class="ion-text-center">
      <!-- Start -->
      <ion-col size="4">
        <base-date-display :datetime="startDate"></base-date-display>
        <p class="ion-text-uppercase action-label">Arrival</p>
      </ion-col>

      <!-- Number Nights -->
      <ion-col size="4" class="vertical-align-center">
        <svg height="2" width="20" class="float-left">
          <line
            x1="0"
            y1="0"
            x2="20"
            y2="0"
            style="stroke: rgb(206, 206, 206); stroke-width: 4"
          />
        </svg>
        <div>
          <span class="action-label">{{ reservationNumNights }}</span><br />
          <span class="action-label">nights</span>
        </div>
        <svg height="2" width="20">
          <line
            x1="0"
            y1="0"
            x2="20"
            y2="0"
            style="stroke: rgb(206, 206, 206); stroke-width: 4"
          />
        </svg>
      </ion-col>

      <!-- End -->
      <ion-col size="4">
        <base-date-display :datetime="endDate"></base-date-display>
        <p class="ion-text-uppercase action-label">Departure</p>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
import BaseDateDisplay from "@/components/base/BaseDateDisplay.vue";
import dayjs from 'dayjs';

export default {
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  components: {
    IonGrid,
    IonRow,
    IonCol,
    BaseDateDisplay
  },
  computed:{
    reservationNumNights(){
      return dayjs(this.endDate).diff(this.startDate, 'day')
    }
  }
};
</script>

<style scoped>
.action-label {
  font-size: 14px;
  font-weight: 500;
}

.vertical-align-center {
  height: 100%;
  justify-content: center;
}
</style>